<template>
    <v-app id="inspire" :style="{ backgroundColor }" v-cloak>

        <!-- PC 해더 -->
        <v-app-bar v-if="!$vuetify.breakpoint.mobile" app height="139" absolute elevation="0" color="white" class="header" v-intersect="{ handler: onScroll, options: { threshold: [0, 1.0] } }">

            <!-- PC 네비게이션 -->
            <v-responsive width="100vw" height="100%" class="ma-0 header-shop-bottom">
                <v-responsive width="100%" :max-width="maxWidth" height="100%" class="mx-auto">
                    <header>
                        <div class="header-shop-bottom">
                            <div class="container">
                                <h1 class="logo"><a title="홈으로 이동" @click="navigate('/')"><img src="/images/connbook/ci.jpg"/></a></h1>
                                <div class="gnb_menu">
                                    <div class="list">
                                        <a class="prepare" @click="comingsoon()">
                                            <img src="/images/pinbox/gnb-google.png" alt="구글기프트카드" />
                                        </a>
                                        <a class="prepare" @click="comingsoon()">
                                            <img src="/images/pinbox/gnb-culture.png" alt="컬쳐랜드" />
                                        </a>
                                        <a class="prepare" @click="comingsoon()">
                                            <img src="/images/pinbox/gnb-happy.png" alt="해피머니" />
                                        </a>
                                        <a @click="navigate('/gshop/giftcards?category-code=book')">
                                            <img src="/images/pinbox/gnb-nanuri.png" alt="도서교환권" />
                                        </a>
                                        <a class="prepare" @click="comingsoon()">
                                            <img src="/images/pinbox/gnb-game.png" alt="게임상점" />
                                        </a>
                                        <a class="prepare" @click="comingsoon()">
                                            <img src="/images/pinbox/gnb-hot.png" alt="핫딜상품" />
                                        </a>
                                    </div>
                                </div>
                                <ul class="customer-menu">
                                    <li class="ml-auto"><a @click="navigate('/about')"><span>회사소개</span></a></li>
                                    <li v-if="siteInfo.memberPayable && logoff" class="mx-auto"><a @click="navigate('/login')"><span>로그인</span></a></li>
                                    <li v-if="siteInfo.memberPayable && logon" class="mx-auto"><a @click="logout()"><span>로그아웃</span></a></li>
                                    <li v-if="siteInfo.memberPayable && logoff" class="mr-auto"><a @click="navigate('/join')"><span>회원가입</span></a></li>
                                    <li v-if="siteInfo.memberPayable && logon" class="mr-auto"><a @click="navigate('/member/gshop/orders')"><span>마이페이지</span></a></li>
                                    <v-btn width="100%" rounded elevation="0" color="primary" class="mx-auto" to="/contact">대량거래/체인점문의</v-btn>
                                </ul>
                            </div>
                        </div>
                        <div class="menu"><button type="button" role="button" aria-pressed="false" class="burger-menu">
                                <div class="burger-wrap"><span class="burger-top"></span> <span
                                        class="burger-middle"></span> <span class="burger-bottom"></span></div>
                            </button></div>
                    </header>

                </v-responsive>
            </v-responsive>
        </v-app-bar>

        <!-- Mobile 해더 -->
        <v-app-bar v-if="$vuetify.breakpoint.mobile" height="60" max-height="60" color="white" elevation="2" v-intersect="{ handler: onScroll, options: { threshold: [0, 1.0] } }">
            <v-layout wrap>
                <v-responsive width="100%" height="60">
                    <v-layout align-center fill-height>
                        <!-- Mobile 햄버거 -->
                        <v-app-bar-nav-icon @click="drawer = true"/>

                        <v-spacer/>

                        <!-- Mobile 로고 -->
                        <router-link to="/" class="align-self-center">
                            <img src="/images/connbook/ci.jpg" height="24" style="display:block;" />
                        </router-link>

                        <v-spacer/>

                        <v-sheet width="48"></v-sheet>
                    </v-layout>
                </v-responsive>
            </v-layout>
        </v-app-bar>

        <!-- Mobile 네비게이션 -->
        <v-navigation-drawer v-model="drawer" app fixed temporary height="auto" width="100%" >
            <v-list max-height="100vh" class="overflow-auto pa-0">

                <!-- 로그인 / 로그아웃 -->
                <v-list-item class="primary" dark>
                    <v-list-item-title>
                        <span>콘앤북</span>
                    </v-list-item-title>
                    <v-list-item-action @click="drawer = false"><v-icon>mdi-close</v-icon></v-list-item-action>
                </v-list-item>

                <!-- Mobile 서브메뉴 : 회사소개 -->
                <v-list-item class="white" active-class="no-active" to="/about">
                    <v-list-item-title><b>회사소개</b></v-list-item-title>
                </v-list-item>

                <v-divider/>

                <!-- Mobile 서브메뉴 : 문의하기 -->
                <v-list-item class="white" active-class="no-active" to="/board/question">
                    <v-list-item-title><b>문의하기</b></v-list-item-title>
                </v-list-item>

                <v-divider/>

                <!-- Mobile 서브메뉴 : 도서 -->
                <v-list-group class="white">
                    <v-list-item-title slot="activator"><b>고객센터</b></v-list-item-title>
                    <v-divider/>
                    <v-list-item-group>
                        <v-list-item active-class="white secondary--text" to="/center/notifications">
                            <v-list-item-title>공지사항</v-list-item-title>
                        </v-list-item>
                        <v-list-item active-class="white secondary--text" to="/center/faqs">
                            <v-list-item-title>자주묻는질문</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list-group>

                <v-divider/>

                <!-- Mobile 서브메뉴 : 상품권 -->
                <v-list-item class="white" active-class="no-active" to="/gshop/giftcards">
                    <v-list-item-title><b>기프트샵</b></v-list-item-title>
                </v-list-item>

                <v-divider/>

                <template v-if="siteInfo.memberPayable && logon">
                    <v-list-group  class="white">
                        <v-list-item-title slot="activator"><b>마이페이지</b></v-list-item-title>
                        <v-divider/>
                        <v-list-item-group>
                            <v-list-item active-class="no-active" to="/member/profile">
                                <v-list-item-title>회원정보수정</v-list-item-title>
                            </v-list-item>
                            <v-list-item active-class="no-active" to="/member/gshop/orders">
                                <v-list-item-title>상품권 구매내역</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list-group>
                    
                    <v-divider/>
                </template>

                <v-card elevation="0" class="pa-5">
                    <v-card-subtitle class="pa-0">
                        <span class="black--text">대표전화</span>
                    </v-card-subtitle>
                    <v-divider class="pa-0 my-2"/>
                    <v-card-title class="pa-0">
                        <span class="mt-2 mr-1 subtitle-2 black--text">Tel.</span>
                        <span class="headline primary--text">{{ siteInfo.tel }}</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <span class="caption black--text">평일 10:00 - 17:00 / 점심 12:30 - 13:30</span>
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <span class="caption black--text">주말 및 공휴일 휴무</span>
                    </v-card-text>
                </v-card>

                <template v-if="siteInfo.memberPayable">
                    <v-divider />
                    <v-list-item class="justify-end">
                        <template v-if="logon">
                            <v-list-item-action class="ma-0">
                                <v-btn plain @click="logout"><v-icon left size="16">{{ mdiLogoutVariant }}</v-icon>로그아웃</v-btn>
                            </v-list-item-action>
                        </template>
                        <template v-else>
                            <v-list-item-action class="ma-0">
                                <v-btn plain active-class="no-active" to="/login"><v-icon left size="16">{{ mdiLockOutline }}</v-icon>로그인</v-btn>
                            </v-list-item-action>
                            <v-list-item-action class="ma-0">
                                <v-btn plain active-class="no-active" to="/join"><v-icon left size="16">{{ mdiAccountPlus }}</v-icon>회원가입</v-btn>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container fluid class="overflow-visible">
                <slot />
            </v-container>
        </v-main>

        <v-divider/>

        <v-responsive min-height="200" v-intersect="{ handler: onFooter, options: { threshold: [0, 1.0] } }">
            <v-footer color="#ffffff" class="overflow-auto footer-serve" :class="{ 'justify-center': !$vuetify.breakpoint.mobile }">
                <v-responsive width="auto" :max-width="maxWidth">
                    <v-row align="center" no-gutters>
                        <v-col cols="auto" class="col">
                            <router-link to="/terms/tos">이용약관</router-link>
                        </v-col>
                        <v-divider vertical class="separator"/>
                        <v-col cols="auto">
                            <router-link to="/terms/privacy">개인정보취급방침</router-link>
                        </v-col>
                        <v-divider vertical class="separator"/>
                        <v-col cols="auto">
                            <router-link to="/contact">대량거래/체인점 문의</router-link>
                        </v-col>
                        <v-divider vertical class="separator"/>
                        <v-col cols="auto">
                            <router-link to="/board/question">고객센터</router-link>
                        </v-col>
                    </v-row>
                </v-responsive>
            </v-footer>

        <v-divider />

            <v-responsive width="100vw" :max-width="maxWidth" class="mx-auto">
                <div class="footer-bottom">
                    <div class="container">
                        <span class="footer-logo">
                            <img src="/images/connbook/ci.jpg" alt="logo" width="182">
                        </span>
                        <div class="footer_information">
                            <div class="footer_information_box">
                                <p><strong>콘앤북</strong></p>
                            </div>
                            <p>
                                <strong>대표</strong> : 최영진<span style="width: 20px; text-align: center; color: #e7e7e7;" class="footer-info-line">ㅣ</span><br class="sp">
                                <strong>사업자등록증</strong> : 196-56-00671<span style="width: 20px; text-align: center; color: #e7e7e7;" class="footer-info-line">ㅣ</span><br class="sp">
                                <strong>통신판매업</strong> : 제 2022-경기광명-1476호<span style="width: 20px; text-align: center; color: #e7e7e7;" class="footer-info-line">ㅣ</span><br class="sp">
                                <strong>주소</strong> : 경기도 광명시 일직로12번길 19, 1동 314호(일직동, 동일센타시아)<br>
                                <strong>대표전화번호</strong> : 010-8020-5075<span style="width: 20px; text-align: center; color: #e7e7e7;" class="footer-info-line">ㅣ</span><br class="sp">
                                <strong>호스팅</strong> : UXI
                            </p>
                            <div class="copyright">
                                <p>COPYRIGHT ⓒ CONNBOOK. ALL RIGHTS RESERVED.</p>
                            </div>
                        </div>
                        <div class="footer_customer">
                            <!-- KB에스크로 이체 인증마크 적용 시작 -->
                            <form name="KB_AUTHMARK_FORM" method="get">
                            <input type="hidden" name="page" value="B009111"/>
                            <input type="hidden" name="cc" value="b010807:b008491"/>
                            <input type="hidden" name="mHValue" value='07c886485fc95b42f849730f96162307'/>
                            </form>
                            <a href="http://escrow1.kbstar.com/quics?page=C016760&mHValue=07c886485fc95b42f849730f96162307" target="_blank" onclick="javascript:onPopKBAuthMark();return false;">
                            <img src="http://img1.kbstar.com/img/escrow/escrowcmark.gif" border="0"/>
                            </a>
                            <!-- KB에스크로 이체 인증마크 적용 종료 -->
                        </div>
                    </div>
                </div>
            </v-responsive>
        </v-responsive>
        
        <slot name="fab" v-bind:scrollTo="scrollTo" v-bind:showScroll="showScroll">
            <a href="https://talk.naver.com/ct/w4b6gb" target="_blank" class="quick"><img src="/images/pinbox/n-icon.png" alt=""> <p>실시간 채팅상담</p></a>
        </slot>
    </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mdiChevronUp, mdiAccountPlusOutline, mdiTextBoxOutline, mdiLockOutline, mdiCartOutline, mdiAccountOutline, mdiLogoutVariant, mdiAccountPlus } from "@mdi/js";
import GlobalNavigationBar from "@/components/client/gnb/global-navigation-bar.vue";

export default {
    components: {
        GlobalNavigationBar,
    },
    data(){
        return {
            mdiChevronUp,
            mdiTextBoxOutline,
            mdiAccountPlusOutline,
            mdiAccountOutline,
            mdiLockOutline,
            mdiCartOutline,
            mdiLogoutVariant,
            mdiAccountPlus,

            maxWidth: 1200,

            drawer: false,

            isActive: false,

            showScroll: false,
            showFooter: false,

            searchValue: -1 < this.$route.path.indexOf("/search") ? this.$route.query.searchValue : null,
        }
    },
    mounted(){
        this.getSiteInfo();
        // this.$nextTick(_ => this.isActive = true )
    },
    methods: {
        ...mapActions("siteInfo", ["getSiteInfo"]),
        login(){
            this.$router.push(`/login`);
        },
        logout() {
            const go = confirm("로그아웃 하시겠습니까?");
            if (go) this.$store.dispatch("logout");
        },
        search(){
            if(!this.searchValue){
                alert("검색어 키워드를 입력해주세요");
                return;
            }

            this.$router.push(`/search?searchValue=${this.searchValue}`);
        },

        navigate(url){
            this.$router.push(url);
        },
        comingsoon(){
            alert("서비스 준비중 입니다");
        },

        open(url){
            window.open(url);
        },

        onFooter(entries, observer) {
            this.showFooter = entries[0].isIntersecting;
        },

        onScroll(entries, observer) {
            this.showScroll = !entries[0].isIntersecting;
        },

        scrollTo(x, y){
            return window.scrollTo(x, y);
        }
    },
    computed: {
        ...mapState(["siteInfo"]),
        accessToken(){ return this.$store.state.accessToken; },
        logon(){ return !!this.accessToken },
        logoff(){ return !this.accessToken },
        backgroundColor(){
            return this.$route.path == "/" ? "white" : "var(--v-content-base) !important";
        }
    },
    watch: {
        accessToken(){ if(!this.accessToken){ this.$router.go(0); } },
    }
}
</script>

<style scoped>
.v-app-bar { z-index: 2; }

#inspire .container { padding: 0px; }

ul { list-style: none; }

header { position: relative; background: #fff; }
.header-shop-top { background: #f4f4f4; border-bottom: 1px solid #e5e5e5; }
.customer-menu { display: flex; flex-wrap: wrap;justify-content: space-around; flex-direction: row-reverse; width: 207px;}
.customer-menu:after { content: ''; display: block; clear: both; }
.customer-menu li { }
.customer-menu li:nth-child(6){}
.customer-menu li a { display: block; margin-bottom: 10px; text-align: center; color: #888888; font-size: 14px; }
.customer-menu li:first-child a { padding-left: 0; }
.customer-menu li:last-child a { padding-right: 0; border-right: none; }

.header-shop-bottom { position: relative; height: 140px; border-bottom: 1px solid #ddd; }
.header-shop-bottom .container { display: flex; justify-content: space-around;align-items: center; position: relative; width: 100%; height: 100%; }
.logo {display: block;  width: 210px; height: 106px; }
.logo a { display: flex; align-items: center; width: 100%; height: 100%; background: url('/child/img/ci.png') no-repeat center; }

.gnb_menu .list{display: flex; justify-content:space-around; width: 575px;}

.sns-ul { float: left; padding-top: 40px; }
.sns { float: left; margin-right: 10px; }
.sns a { display: block; }
.sns a i { display: block; width: 35px; height: 35px; }
.s1 { background-image: url('/child/img/icon/s1.png'); background-repeat: no-repeat; background-position: center; }
.s2 { background-image: url('/child/img/icon/s2.png'); background-repeat: no-repeat; background-position: center; }
.s3 { background-image: url('/child/img/icon/s3.png'); background-repeat: no-repeat; background-position: center; }

.header-top { height: 80px; background: #fff; }
.header-top .container { position: relative; height: 100%; }
.header-bottom { border-bottom: 1px solid #eee; }
#header-logo { position: absolute; top: 18px; left: 40px; margin: auto; width: 195px; height: 43px; }
.ci { display: block; width: 100%; height: 100%; }
.ci span { display: block; width: 100%; height: 100%; background: url('/child/img/ci.png') no-repeat center; }

/* GNB */
.gnb { position: relative; }
.gnb .list { display: flex; width: 575px;}



.gnb-sub { position: absolute; top: 56px; left: 0; width: 100%; background: #fafafa; box-shadow: 0px 5px 10px 0px rgba(0,0,0,.1); }
.sub-sub-ul { overflow: hidden; height: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.sub-sub-ul:after { content: ''; display: block; clear: both; }
.sub-sub-ul li { float: left; height: 50px; }
.sub-sub-ul li a { display: block; padding: 15px 20px 14px; font-size: 15px; color: #666666; }
.sub-sub-ul li.on a { color: #444444; font-weight: 500; }
.sub-sub-ul.active { height: 50px; }

.sub-sub-ul:nth-child(2) { padding-left: 110px; }
.sub-sub-ul:nth-child(3) { padding-left: 240px; }
/* .header {box-shadow:0px 2px 6px rgba(200,200,200,.1) !important; z-index:10;} */

/* .header::before{
    content: ''; position: absolute; top: 0; left: 0;
    width: 100%; height: 100%; background: #ffffff; box-shadow: 0px 5px 13px 0px rgba(74,74,74,.20) !important;
    -webkit-transition: all ease .15s; transition: all ease .15s;
} */
/* .header >>> .v-toolbar__content{ padding: 0 !important; } */

/* .prepend-menu { padding: 24px 36px;}
.prepend-menu ul { list-style: none; }
.prepend-menu > a { display: block; margin-top: 16px; color: black; }
.prepend-menu > div { margin-top: 16px; }
.prepend-menu > *:first-child { margin-top: 0px; }
.prepend-menu .sub-menu li { margin-top: 8px; }
.prepend-menu .sub-menu a { position: relative; color: black; }
.prepend-menu .sub-menu a::before{
    content: ''; position: absolute; bottom: 0; left: -2px;
    width: calc(100% + 4px); height: 10px; background: #2676f3;
    -webkit-transform: scaleX(0); transform: scaleX(0);
    -webkit-transform-origin: left; transform-origin: left;
    -webkit-transition: all ease .15s; transition: all ease .15s;
    opacity: .25;
} */
/* .prepend-menu .sub-menu a:hover::before { -webkit-transform: scaleX(1); transform: scaleX(1); } */
/* 
.quick-menu .point { display: block; position: absolute; top: -60px; left: 8px; margin: 0 auto 7px; animation: point 2s ease infinite; }
.quick-menu >>> .v-btn__content span {
    font-size: 14px !important;
    letter-spacing: -0.06em !important;
} */

/* @keyframes point {
    0%, 30%, 60%, 100% { -webkit-transform: translateY(0); transform: translateY(0); }
    15%, 45% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
} */
.footer-serve {
    padding: 0;
}
.footer-serve .col {
    position: relative;
    padding: 0 15px !important;
    line-height: 62px;
}

.footer-serve .separator {
    height: 15px;
    width: 1px;
    margin: auto 0;
}
.footer-serve a {
    font-size: 15px;
    font-weight: 500;
    color: #555555;
}

.sp { display: none; }
@media (max-width:768px) {
    .sp { display: block; }
    .pc { display: none; }
}

.footer-bottom { padding-top: 50px;padding-bottom: 50px; }
.footer-bottom .container { display: flex;}
.footer-logo { display: flex; justify-content: center; align-items: center; padding-right: 70px; }
.footer-logo img{align-items: center;}

.footer-bottom ul.list li { padding: 0 15px; }
.footer-bottom ul.list li a { font-size: 14px; color: #777777; cursor: text; }
.footer-bottom .container{display: flex;}
.footer_information{color:#777; width: calc(100% - 222px)}
.footer-info-line{
    display: inline-block;
}
.footer_information_box{
    font-size: 18px;
    margin-bottom: 5px;
    color:#555;
}
.footer_information_box strong{font-weight: 600;}
.footer_customer{ display: flex; flex-direction: column; margin-left: auto; padding-left: 60px;}
.footer_customer>div{display: flex; flex-direction: row;align-items: flex-end; }
.footer_customer img{ left: 0;}
.footer_customer h5{ font-size: 16px;margin-right: 10px; }
.footer_customer h6{ font-size: 25px; }
.footer_customer p{ font-size: 16px; margin-top: 5px;}
.copyright { margin-top: 12px; padding: 0; font-size: 15px; color: #aaaaaa; }

/* .top-button {
    min-width:57px !important;
    height:57px !important;
    padding:10px 15px !important;
} */
@media (max-width: 1024px){
    .footer-bottom .container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
@media (max-width: 768px){
    .footer-serve a {
        font-size:16px;
    }
    .footer-top .container{ flex-direction: column;justify-content: center;}
    .pt-list{justify-content: center;}
    .f-sns-ul{flex-direction: row;justify-content: center;margin-bottom: 10px;}
    .f-sns:first-child{margin-right: 10px;}
    .f-sns:last-child{margin: 0;}
    .pt-list li a {
        display: block;
        font-size: 14px;
        padding: 10px 3px;
    }
    .footer-bottom ul.list li a { font-size: 13px; }

    .footer-logo{display: none;}

    .footer_information{width: 100%;}

    .footer-info-line{
        display: none;
    }
}
@media (max-width: 550px){
    .footer-top .container{flex-direction: column;}
    .pt-list{ flex-wrap:wrap; justify-content: space-between; padding:5px 0; }
    .pt-list li{ width: 50%; font-size: 12px; line-height: 1.2; padding: 0 4px; }
    .pt-list li:after{content:none;}
    .footer-bottom{padding-top: 30px;padding-bottom: 30px;}
    .footer-bottom .container{flex-direction: column-reverse;}
    .footer_information{
        padding-right:0;
        border-right:0;
    }
    .footer_customer{ width:100%; padding-left: 0;margin-bottom: 30px;padding-bottom: 30px;border-bottom: 1px solid #ddd;}
    .footer_customer a{text-align: center;}
    .footer_customer img{margin:auto;}
    .copyright{padding: 0; margin-top: 3px;}
}


/* qucik */
.quick{
    position: fixed;
    width: 155px;
    height: 52px;
    border-radius: 26px;
    background-color: #00c83c;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    right: 40px;
    z-index: 1;
}

.quick img{
    display: block;
    margin-right: 7px;
    position: relative;
    top: 3px;
}

.quick p{
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
}

@media (max-width: 768px){
    .quick{
        width: 120px;
        height: 44px;
        border-radius: 22px;
        bottom: 15px;
        right: 15px;

    }

    .quick img{
        margin-right: 4px;
        top: 0;
    }

    .quick p{
        color: #fff;
        font-size: 12px;
    }
}

</style>