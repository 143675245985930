var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "id": "inspire"
    }
  }, [!_vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "header",
    attrs: {
      "app": "",
      "height": "139",
      "absolute": "",
      "elevation": "0",
      "color": "white"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-0 header-shop-bottom",
    attrs: {
      "width": "100vw",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100%",
      "max-width": _vm.maxWidth,
      "height": "100%"
    }
  }, [_c('header', [_c('div', {
    staticClass: "header-shop-bottom"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "title": "홈으로 이동"
    },
    on: {
      "click": function ($event) {
        return _vm.navigate('/');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/connbook/ci.jpg"
    }
  })])]), _c('div', {
    staticClass: "gnb_menu"
  }, [_c('div', {
    staticClass: "list"
  }, [_c('a', {
    staticClass: "prepare",
    on: {
      "click": function ($event) {
        return _vm.comingsoon();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-google.png",
      "alt": "구글기프트카드"
    }
  })]), _c('a', {
    staticClass: "prepare",
    on: {
      "click": function ($event) {
        return _vm.comingsoon();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-culture.png",
      "alt": "컬쳐랜드"
    }
  })]), _c('a', {
    staticClass: "prepare",
    on: {
      "click": function ($event) {
        return _vm.comingsoon();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-happy.png",
      "alt": "해피머니"
    }
  })]), _c('a', {
    on: {
      "click": function ($event) {
        return _vm.navigate('/gshop/giftcards?category-code=book');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-nanuri.png",
      "alt": "도서교환권"
    }
  })]), _c('a', {
    staticClass: "prepare",
    on: {
      "click": function ($event) {
        return _vm.comingsoon();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-game.png",
      "alt": "게임상점"
    }
  })]), _c('a', {
    staticClass: "prepare",
    on: {
      "click": function ($event) {
        return _vm.comingsoon();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/pinbox/gnb-hot.png",
      "alt": "핫딜상품"
    }
  })])])]), _c('ul', {
    staticClass: "customer-menu"
  }, [_c('li', {
    staticClass: "ml-auto"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.navigate('/about');
      }
    }
  }, [_c('span', [_vm._v("회사소개")])])]), _vm.siteInfo.memberPayable && _vm.logoff ? _c('li', {
    staticClass: "mx-auto"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.navigate('/login');
      }
    }
  }, [_c('span', [_vm._v("로그인")])])]) : _vm._e(), _vm.siteInfo.memberPayable && _vm.logon ? _c('li', {
    staticClass: "mx-auto"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_c('span', [_vm._v("로그아웃")])])]) : _vm._e(), _vm.siteInfo.memberPayable && _vm.logoff ? _c('li', {
    staticClass: "mr-auto"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.navigate('/join');
      }
    }
  }, [_c('span', [_vm._v("회원가입")])])]) : _vm._e(), _vm.siteInfo.memberPayable && _vm.logon ? _c('li', {
    staticClass: "mr-auto"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.navigate('/member/gshop/orders');
      }
    }
  }, [_c('span', [_vm._v("마이페이지")])])]) : _vm._e(), _c('v-btn', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100%",
      "rounded": "",
      "elevation": "0",
      "color": "primary",
      "to": "/contact"
    }
  }, [_vm._v("대량거래/체인점문의")])], 1)])]), _c('div', {
    staticClass: "menu"
  }, [_c('button', {
    staticClass: "burger-menu",
    attrs: {
      "type": "button",
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('div', {
    staticClass: "burger-wrap"
  }, [_c('span', {
    staticClass: "burger-top"
  }), _vm._v(" "), _c('span', {
    staticClass: "burger-middle"
  }), _vm._v(" "), _c('span', {
    staticClass: "burger-bottom"
  })])])])])])], 1)], 1) : _vm._e(), _vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    }],
    attrs: {
      "height": "60",
      "max-height": "60",
      "color": "white",
      "elevation": "2"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "60"
    }
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        _vm.drawer = true;
      }
    }
  }), _c('v-spacer'), _c('router-link', {
    staticClass: "align-self-center",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": "/images/connbook/ci.jpg",
      "height": "24"
    }
  })]), _c('v-spacer'), _c('v-sheet', {
    attrs: {
      "width": "48"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "fixed": "",
      "temporary": "",
      "height": "auto",
      "width": "100%"
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    staticClass: "overflow-auto pa-0",
    attrs: {
      "max-height": "100vh"
    }
  }, [_c('v-list-item', {
    staticClass: "primary",
    attrs: {
      "dark": ""
    }
  }, [_c('v-list-item-title', [_c('span', [_vm._v("콘앤북")])]), _c('v-list-item-action', {
    on: {
      "click": function ($event) {
        _vm.drawer = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/about"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("회사소개")])])], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/board/question"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("문의하기")])])], 1), _c('v-divider'), _c('v-list-group', {
    staticClass: "white"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("고객센터")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/notifications"
    }
  }, [_c('v-list-item-title', [_vm._v("공지사항")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/faqs"
    }
  }, [_c('v-list-item-title', [_vm._v("자주묻는질문")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/gshop/giftcards"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("기프트샵")])])], 1), _c('v-divider'), _vm.siteInfo.memberPayable && _vm.logon ? [_c('v-list-group', {
    staticClass: "white"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("마이페이지")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/profile"
    }
  }, [_c('v-list-item-title', [_vm._v("회원정보수정")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/gshop/orders"
    }
  }, [_c('v-list-item-title', [_vm._v("상품권 구매내역")])], 1)], 1)], 1), _c('v-divider')] : _vm._e(), _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "black--text"
  }, [_vm._v("대표전화")])]), _c('v-divider', {
    staticClass: "pa-0 my-2"
  }), _c('v-card-title', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "mt-2 mr-1 subtitle-2 black--text"
  }, [_vm._v("Tel.")]), _c('span', {
    staticClass: "headline primary--text"
  }, [_vm._v(_vm._s(_vm.siteInfo.tel))])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("평일 10:00 - 17:00 / 점심 12:30 - 13:30")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("주말 및 공휴일 휴무")])])], 1), _vm.siteInfo.memberPayable ? [_c('v-divider'), _c('v-list-item', {
    staticClass: "justify-end"
  }, [_vm.logon ? [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLogoutVariant))]), _vm._v("로그아웃")], 1)], 1)] : [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/login"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLockOutline))]), _vm._v("로그인")], 1)], 1), _c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/join"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiAccountPlus))]), _vm._v("회원가입")], 1)], 1)]], 2)] : _vm._e()], 2)], 1), _c('v-main', [_c('v-container', {
    staticClass: "overflow-visible",
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2)], 1), _c('v-divider'), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onFooter,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onFooter, options: { threshold: [0, 1.0] } }"
    }],
    attrs: {
      "min-height": "200"
    }
  }, [_c('v-footer', {
    staticClass: "overflow-auto footer-serve",
    class: {
      'justify-center': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "color": "#ffffff"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "auto",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "col",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/tos"
    }
  }, [_vm._v("이용약관")])], 1), _c('v-divider', {
    staticClass: "separator",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/privacy"
    }
  }, [_vm._v("개인정보취급방침")])], 1), _c('v-divider', {
    staticClass: "separator",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("대량거래/체인점 문의")])], 1), _c('v-divider', {
    staticClass: "separator",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/board/question"
    }
  }, [_vm._v("고객센터")])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100vw",
      "max-width": _vm.maxWidth
    }
  }, [_c('div', {
    staticClass: "footer-bottom"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('span', {
    staticClass: "footer-logo"
  }, [_c('img', {
    attrs: {
      "src": "/images/connbook/ci.jpg",
      "alt": "logo",
      "width": "182"
    }
  })]), _c('div', {
    staticClass: "footer_information"
  }, [_c('div', {
    staticClass: "footer_information_box"
  }, [_c('p', [_c('strong', [_vm._v("콘앤북")])])]), _c('p', [_c('strong', [_vm._v("대표")]), _vm._v(" : 최영진"), _c('span', {
    staticClass: "footer-info-line",
    staticStyle: {
      "width": "20px",
      "text-align": "center",
      "color": "#e7e7e7"
    }
  }, [_vm._v("ㅣ")]), _c('br', {
    staticClass: "sp"
  }), _c('strong', [_vm._v("사업자등록증")]), _vm._v(" : 196-56-00671"), _c('span', {
    staticClass: "footer-info-line",
    staticStyle: {
      "width": "20px",
      "text-align": "center",
      "color": "#e7e7e7"
    }
  }, [_vm._v("ㅣ")]), _c('br', {
    staticClass: "sp"
  }), _c('strong', [_vm._v("통신판매업")]), _vm._v(" : 제 2022-경기광명-1476호"), _c('span', {
    staticClass: "footer-info-line",
    staticStyle: {
      "width": "20px",
      "text-align": "center",
      "color": "#e7e7e7"
    }
  }, [_vm._v("ㅣ")]), _c('br', {
    staticClass: "sp"
  }), _c('strong', [_vm._v("주소")]), _vm._v(" : 경기도 광명시 일직로12번길 19, 1동 314호(일직동, 동일센타시아)"), _c('br'), _c('strong', [_vm._v("대표전화번호")]), _vm._v(" : 010-8020-5075"), _c('span', {
    staticClass: "footer-info-line",
    staticStyle: {
      "width": "20px",
      "text-align": "center",
      "color": "#e7e7e7"
    }
  }, [_vm._v("ㅣ")]), _c('br', {
    staticClass: "sp"
  }), _c('strong', [_vm._v("호스팅")]), _vm._v(" : UXI ")]), _c('div', {
    staticClass: "copyright"
  }, [_c('p', [_vm._v("COPYRIGHT ⓒ CONNBOOK. ALL RIGHTS RESERVED.")])])]), _c('div', {
    staticClass: "footer_customer"
  }, [_c('form', {
    attrs: {
      "name": "KB_AUTHMARK_FORM",
      "method": "get"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "page",
      "value": "B009111"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cc",
      "value": "b010807:b008491"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mHValue",
      "value": "07c886485fc95b42f849730f96162307"
    }
  })]), _c('a', {
    attrs: {
      "href": "http://escrow1.kbstar.com/quics?page=C016760&mHValue=07c886485fc95b42f849730f96162307",
      "target": "_blank",
      "onclick": "javascript:onPopKBAuthMark();return false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "http://img1.kbstar.com/img/escrow/escrowcmark.gif",
      "border": "0"
    }
  })])])])])])], 1), _vm._t("fab", function () {
    return [_c('a', {
      staticClass: "quick",
      attrs: {
        "href": "https://talk.naver.com/ct/w4b6gb",
        "target": "_blank"
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/pinbox/n-icon.png",
        "alt": ""
      }
    }), _vm._v(" "), _c('p', [_vm._v("실시간 채팅상담")])])];
  }, {
    "scrollTo": _vm.scrollTo,
    "showScroll": _vm.showScroll
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }